<template>
  <v-container fluid fill-height>
    <v-row fill-height>
      <v-tabs
        background-color="transparent"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        height="80vh"
        v-if="orders.length > 0"
      >
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab v-for="(doc, key) in orders" :key="key" class="pa-3">
          <v-card
            height="100%"
            min-height="300px"
            elevation="5"
            :color="status_color[doc.status]"
          >
            <v-card-title>{{ doc.doc_code }}</v-card-title>
            <v-card-subtitle
              >{{ doc.issue_date }} /
              {{ get_timeAgo(doc.issue_date) }}</v-card-subtitle
            >

            <v-card-text style="overflow: auto">
              <v-data-table
                :headers="header"
                :items="items[doc.doc_code]"
                :items-per-page="-1"
                item-key="order_id"
                hide-default-footer
                sort-by="name"
                mobile-breakpoint="0"
                class="table-cursor"
                loading-text="Cargando ..."
              >
                <template v-slot:top></template>
                <template
                  v-slot:[`item.product_name`]="{ item }"
                  class="pa-0 ma-0"
                >
                  <b class="text-uppercase">
                    {{ item.qty }} X {{ item.product_name }}
                  </b>
                  <br />
                  <span v-if="item.note" v-text="item.note"> </span>
                  <template v-if="item.product_options">
                    <v-row>
                      <v-col
                        cols="12"
                        v-for="(value, key) in item.product_options"
                        :key="key"
                        justify="center"
                      >
                        <v-data-table
                          :headers="headerSelected"
                          hide-default-header
                          hide-default-footer
                          :items-per-page="-1"
                          mobile-breakpoint="0"
                          :items="value"
                          item-key="product_name"
                          :single-select="selecttype(item.product_options[key])"
                          dense
                        >
                        <template v-slot:[`item.price`]="{ item }" class="pa-0 ma-0">
                          {{parseFloat(item.price).toLocaleString()}}
                        </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template v-slot:[`item.price`]="{ item }" class="pa-0 ma-0">
                  <span
                    >{{
                      (
                        item.qty *
                        (parseFloat(item.price) +
                          parseFloat(item.value_options))
                      ).toLocaleString()
                    }}
                  </span>
                </template>
              </v-data-table>
              <v-data-table
                :headers="header_payments"
                :items="payments[doc.doc_code]"
                :items-per-page="-1"
                item-key="code"
                hide-default-footer
                sort-by="name"
                mobile-breakpoint="0"
                class="table-cursor"
                loading-text="Cargando ..."
              >
                <template v-slot:top></template>
                <template
                  v-slot:[`item.product_name`]="{ item }"
                  class="pa-0 ma-0"
                >
                  <b class="text-uppercase">{{ item.product_name }}</b>
                  <span v-html="item.description"> </span>
                  <span>{{ parseFloat(item.price).toLocaleString() }}</span>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="status">
              <v-select
                @change="statusChange(doc)"
                v-model="doc.status"
                :items="status_lst"
                label="Pendiente"
                solo
                hide-details
              ></v-select>
            </v-card-actions>
          </v-card>
        </v-tab>
      </v-tabs>

      <v-layout align-center justify-center v-else>
        <v-flex xs12 sm8 md4>
          <v-card flat>
            <v-sheet color="`grey`" class="pa-3">
              <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
              ></v-skeleton-loader>
            </v-sheet>
            <v-card-text class="text-center"
              ><h1>No tiene Ordenes pendientes</h1></v-card-text
            >
          </v-card>
        </v-flex>
      </v-layout>
    </v-row>
  </v-container>
</template>

<script>
// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
  apiKey: "AIzaSyC_aeBEppq_z9pxVQ4Z6-vkMy_OZuQNPJU",
  authDomain: "qr4r-restaurant.firebaseapp.com",
  databaseURL: "https://qr4r-restaurant-default-rtdb.firebaseio.com",
  storageBucket: "qr4r-restaurant.appspot.com",
};

import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

import {
  webserver,
  getToday,
  getIssueDate,
  timeSince,
} from "../../services/webserver.js";

export default {
  components: {},
  data() {
    return {
      orders: [],
      items: [],
      payments: [],
      status_lst: ["Aceptado", "En proceso", "Listo"],
      status_color: {
        Aceptado: "#FFF3E0",
        "En proceso": "#B9F6CA",
        Listo: "white",
      },
      header: [
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
      ],
      header_payments: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: true,
          value: "payment_type",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "payment_value",
        },
      ],
      headerSelected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
      ],
    };
  },
  mounted() {
    this.get_orders();
    this.start_orders();
  },
  methods: {
    start_orders() {
      const starCountRef = ref(db, "orders/D7627F279BF2E/");
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        this.playSound();
        this.get_orders();
      });
    },
    playSound() {
      var sound = "https://smartchef.pro/mp3/service.mp3";

      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
    get_orders() {
      var qry = {
        account: "575002",
        store: "D7627F279BF2E",
        issueDateFrom: getIssueDate(2),
        issueDateTo: getToday(),
        filters: [
          {
            field: "status",
            operator: " IN ",
            value: "'','Aceptado','En proceso'",
          },
        ],
      };
      this.loading_status = true;
      webserver("get_docs", qry, (data) => {
        this.loading_status = false;
        this.orders = data.docs;
        var itms = data.items;
        var pymnts = data.payments;

        itms.forEach((itm) => {
          itm.product_options = JSON.parse(itm.product_options);
        });
        //console.log(itms);
        this.items = itms.reduce(function (r, a) {
          r[a.doc_code] = r[a.doc_code] || [];
          r[a.doc_code].push(a);
          return r;
        }, Object.create(null));

        this.payments = pymnts.reduce(function (r, a) {
          r[a.doc_code] = r[a.doc_code] || [];
          r[a.doc_code].push(a);
          return r;
        }, Object.create(null));
        //console.log(this.orders, this.items, this.payments);
      });
    },
    get_timeAgo(e) {
      return timeSince(Date.parse(e));
    },
    oSelected(e) {
      return JSON.parse(e);
    },
    selecttype(e) {
      return e[0].unique;
    },
    statusChange(e) {
      var qry = {
        account: e.account,
        store: e.store,
        doc_code: e.doc_code,
        status: e.status,
      };
      this.loading_status = true;
      webserver("update_doc_status", qry, () => {
        this.loading_status = false;
        this.get_orders();
      });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.status {
  position: absolute;
  bottom: 0;
  margin: auto;
  width: 100%;
}
</style>